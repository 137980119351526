<template>
  <section>
    <ContentCard class="px-4 pt-4 mb-8 flex justify-between">
      <div class="pb-4">
        <h3>{{ group.groupName }}</h3>
        <p class="text-gray-dark">Description: {{ group.description }}</p>
        <p class="text-gray-dark">Group ID: {{ group.groupID }}</p>
        <p class="text-gray-dark">Account ID: {{ group.accountID }}</p>
      </div>
    </ContentCard>
    <div v-if="loading" class="w-full flex items-center justify-center">
      <ToznyLoader class="text-tozny w-12 h-12" />
    </div>
  </section>
</template>

<script>
import ContentCard from '@/Common/ContentCard'
import ToznyLoader from '@/Common/ToznyLoader'
export default {
  name: 'GroupDetails',
  components: {
    ContentCard,
    ToznyLoader,
  },
  props: {
    group: Object,
    statusUpdating: Boolean,
    loading: Boolean,
  },
  methods: {},
}
</script>
