<template>
  <ul class="flex">
    <li v-for="(action, i) in actions" v-bind:key="`$action-${i}`" class="ml-4">
      <a
        herf="#!"
        @click.prevent="$emit('action', action)"
        :class="linkColor"
        class="flex cursor-pointer no-underline"
        :data-auto-id="autoIdVal(action.event)"
        :id="autoIdVal(action.event)"
      >
        <span class="sr-only">{{ action.altText }}</span>
        <i class="material-icons-outlined md-24 micon">{{ action.icon }}</i>
      </a>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'Actions',
  props: {
    actions: {
      type: Array,
      default: () => [],
      validator(items) {
        for (let item of items) {
          if (!item.icon || typeof item.icon !== 'string') {
            return false
          }
        }
        return true
      },
    },
    linkColor: {
      type: String,
      default: 'text-gray-dark',
    },
    autoId: String,
  },
  methods: {
    autoIdVal(eventName) {
      return this.autoId && eventName ? `${this.autoId}-${eventName}` : false
    },
  },
}
</script>

<style></style>
