<template>
  <section>
    <ContentCard data-auto-id="groupmember-list" title="Group Members">
      <template #extras>
        <Actions
          :actions="cardActions"
          @action="handleCardActions"
          auto-id="groupmember-card"
        />
      </template>
      <div class="pt-8 px-4 pb-4">
        <ItemTable
          data-auto-id="groupmmember-table"
          :items="groupMembers"
          :columns="tableColumns"
        >
          <template #actions="{ itemData }">
            <Actions
              :actions="actionObject(itemData)"
              @action="handleAction"
              :auto-id="`group-actions-${itemData}`"
            />
          </template>
        </ItemTable>
      </div>
    </ContentCard>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Actions from '@/Common/Actions'
import ContentCard from '@/Common/ContentCard'
import ItemTable from '@/Common/ItemTable'
export default {
  name: 'GroupMemberList',
  components: {
    Actions,
    ContentCard,
    ItemTable,
  },
  data: () => ({
    actions: [
      {
        event: 'delete',
        altText: 'delete group member',
        icon: 'delete-outline',
      },
    ],
  }),
  props: {
    groupMembers: {
      type: Array,
      default: () => [{ clientId: '', capabilities: [] }],
      validator: (groupMembers) =>
        groupMembers.every((groupMember) => {
          if (typeof groupMember.client_id !== 'string') {
            return false
          }
          return groupMember.capability_names.every(
            (capability) => typeof capability === 'string'
          )
        }),
    },
    errorMessage: String,
  },
  computed: {
    tableColumns() {
      return [
        {
          title: 'Client ID',
          target: 'client_id',
          slot: 'clientId',
        },
        {
          title: 'Capabilities',
          target: 'capabilities',
          slot: 'capabilities',
        },
        {
          title: 'Actions',
          target: 'client_id',
          slot: 'actions',
          className: 'whitespace-no-wrap w-px text-center',
        },
      ]
    },
    cardActions() {
      return [
        {
          event: 'add',
          altText: 'add group member',
          icon: 'add-outline',
        },
      ]
    },
  },
  methods: {
    ...mapActions('groups/group/groupMembers', [
      'transitionStatus',
      'selectGroupMember',
    ]),
    ...mapGetters('groups', ['groupID']),
    handleAction(action) {
      this.$emit(action.event, action.groupMember)
    },
    handleCardActions(action) {
      this.$emit(action.event)
    },
    actionObject(groupMember) {
      return [
        {
          groupMember: groupMember,
          event: 'delete',
          altText: 'delete groupmember',
          icon: 'delete-outline',
        },
      ]
    },
  },
}
</script>
