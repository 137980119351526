<template>
  <MainLayout>
    <Banner
      v-if="status === 'error'"
      type="red"
      button-text="Retry"
      :click-method="initialize"
    >
      <p class="mb-0">{{ errorMessage }}</p>
    </Banner>
    <div class="mb-2">
      <router-link
        class="no-underline text-gray-dark inline-block"
        to="/groups"
      >
        <div class="flex items-center">
          <i class="material-icons">keyboard_arrow_left</i>
          All Groups
        </div>
      </router-link>
    </div>
    <GroupDetails :group="selectedGroup" />
    <AddGroupMember
      v-if="statusAdding"
      submitText="Add A Group Member"
      title="Add A Group Member"
      :errorMessage="errorMessage"
      :loading="status === 'add'"
      @cancel="transitionStatus('idle')"
    />
    <GroupMemberViewer
      v-if="statusIdle"
      :groupMembers="listGM()"
      :loading="status === 'loading'"
      @add="transitionStatus('add')"
      @delete="beginDelete"
    />
    <GroupMemberDeleteDialogue
      :showing="showDeleteDialogue"
      :delete-queue="deleteQueue"
      @confirm="deleteMembers()"
      @cancel="cancelDeleteQueue"
    />
  </MainLayout>
</template>

<script>
import MainLayout from '@/Common/MainLayout/MainLayout'
import Banner from '@/Common/Banner'
import GroupDetails from './Components/GroupDetails'
import GroupMemberViewer from './GroupMember/Components/GroupMemberViewer'
import GroupMemberDeleteDialogue from './GroupMember/Components/GroupMemberDeleteDialogue'
import AddGroupMember from './GroupMember/AddGroupMember'
import { mapState, mapGetters, mapActions } from 'vuex'
export default {
  name: 'group',
  components: {
    MainLayout,
    GroupDetails,
    GroupMemberViewer,
    GroupMemberDeleteDialogue,
    AddGroupMember,
    Banner,
  },
  computed: {
    ...mapState('groups/group', [
      'status',
      'errorMessage',
      'group',
      'initialized',
      'deleteQueue',
    ]),
    ...mapState('groups/group/groupMembers', [
      'status',
      'errorMessage',
      'groupMembers',
      'group',
      'initialized',
      'deleteQueue',
    ]),
    ...mapGetters('groups', ['selectedGroup']),
    ...mapGetters('groups/group/groupMembers', ['confirmDelete']),
    groupIDParam() {
      return this.$route.params.groupId
    },
    statusIdle() {
      return this.status === 'idle' || this.status === 'idle.delete'
    },
    statusAdding() {
      return this.status === 'add' || this.status === 'adding'
    },
    showDeleteDialogue() {
      return this.status === 'idle.delete'
    },
    showGlobalError() {
      return this.status === 'globalError'
    },
    showError() {
      return this.status === 'idle' && this.errorMessage
    },
  },
  methods: {
    ...mapActions('groups/group', {
      initGroup: 'initialize',
    }),
    ...mapActions('groups/group/groupMembers', [
      'initializeGroupMembers',
      'addGroupMember',
      'selectMember',
      'transitionStatus',
      'enqueueDelete',
      'listMembers',
      'cancelDeleteQueue',
      'deleteMembers',
    ]),
    async initialize() {
      // If the group is not loaded or has changed
      if (this.groupID !== this.groupIDParam || this.status === 'globalError') {
        await this.initGroup(this.groupIDParam)
        await this.initializeGroupMembers()
      }
    },
    listGM() {
      const capabilityMap = {
        READ_CONTENT: 'Read',
        SHARE_CONTENT: 'Share',
        MANAGE_MEMBERSHIP: 'Manage',
      }
      return this.groupMembers.map(function (i) {
        let groupMember = Object.assign({}, i)
        groupMember.capabilities = groupMember.capability_names
          .map(function (capability) {
            return capabilityMap[capability]
          })
          .join(', ')
        return groupMember
      })
    },
    beginDelete(groupMember) {
      return this.enqueueDelete(groupMember)
    },
  },
  created: async function () {
    await this.initialize()
  },
  watch: {
    groupIDParam() {
      return this.initialize()
    },
    groupStatus() {
      return this.initialize()
    },
  },
}
</script>
